@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.Contact {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background-color: #4c4c4c;
  padding: 2rem;
  width: 100%;
  margin: 0;
  background: linear-gradient(135deg, #2E1A71, #4528A5, #421A75);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
  color: #dda0dd;
}

.Contact h1, .Contact h2 {
  text-align: center;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.location {
  margin-bottom: 2rem;
  text-align: center;
}

.contact-methods {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 600px;
}

.contact-methods .phone,
.contact-methods .email {
  flex: 1;
  margin: 0 1rem;
  text-align: center;
}

.contact-button {
  display: block;
  padding: 1rem;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0.5rem 0;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #0056b3;
}

.contact-form {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
  color: white;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #ddd;
}

button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 899px) {
  .contact-methods {
    flex-direction: column;
    align-items: center;
  }
  .contact-form {
    max-width: 80%;
  }

  .contact-methods .phone,
  .contact-methods .email {
    margin: 1rem 0;
    width: 100%;
  }
}
