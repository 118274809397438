strong {
    font-size: 1.2rem;
    font-weight: 600;
}

.Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    background-color: #4c4c4c;

    margin: 0;
    background: linear-gradient(135deg, #2E1A71, #4528A5, #421A75);
    background-size: 200% 200%;
    animation: gradientAnimation 15s ease infinite;
    color: #dda0dd;
}

.intro {
    display: flex;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    /* padding: 2rem; */
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.best-pc {
    background-color: #bd5bff;
    height: auto;
    width: 40rem;
    border-radius: 40%;
    mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 72%);
    -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 72%);
}

.hookContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 2rem;
    margin-bottom: 8rem;
}

.hook {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
}

.hooks {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.btn {
    padding: 10px 20px;
    font-size: 2rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #0056b3;
}

.call {
    margin-top: 4rem;
}


@media only screen and (max-width: 899px) {
    .best-pc {
        width: 20rem;
    }
    .hero {
        width: 100%;
    }
    .hook {
        flex-direction: column;
    }
    .intro {
        flex-direction: column;
        align-items: center;
    }
    .introContent {
        padding: 1rem;
    }
}
@media only screen and (min-width: 899px) {
    .intro {
        padding: 2rem 8rem;
        max-width: 70%;
    }
    .introContent {
        max-width: 45%;
        padding: 0 2rem;
    }
}
