.Builds {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
  background-color: #4c4c4c;
  color: #dda0dd;
  background: linear-gradient(135deg, #2E1A71, #4528A5, #421A75);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
}

.Builds h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.buildsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* This can be adjusted as needed */
}

.build {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  width: 100%;
  max-width: 800px; /* Ensure this is respected on smaller screens */
  text-align: center;
}

.build-img {
  width: auto;
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.build-description {
  margin-top: 1rem;
  font-size: 1.1rem;
}

.testimonial {
  font-style: italic;
  color: #ccc;
  margin-top: 1rem;
}

.carousel .slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .slide img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 30rem;
  border-radius: 8px;
}

.control-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .Builds {
    padding: 1rem;
    box-sizing: border-box;
    max-width: 100%;
  }

  .buildsContainer {
    max-width: 90%; /* Adjust the width percentage for better fit */
  }

  .build {
    max-width: 100%;
  }
}
